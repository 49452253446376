import * as React from 'react'

import { HorizontalAlignment, useTheme } from '@thg-commerce/enterprise-core'
import {
  GlobalEditorialWithFeaturePicture,
  GlobalFourItemEditorialPicture,
  GlobalThreeItemEditorialPicture,
  GlobalThreeItemEditorialSubtitleBgPicture,
  GlobalTwoItemEditorialPicture,
  GlobalWidgetSiriusPicture,
} from '@thg-commerce/enterprise-network/src/ApolloProvider/resolvers/Types/index'
import { GlobalSetAndromeda } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  Editorial,
  EditorialProps,
} from '@thg-commerce/enterprise-widget-editorial'
import { BreakpointArray } from '@thg-commerce/gravity-theme'

import { BaseWidgetProps } from '../types'
import { WidgetTypes } from '../WidgetTypes'

export type EditorialWidgetRendererProps =
  | (BaseWidgetProps &
      (
        | GlobalTwoItemEditorialPicture
        | GlobalThreeItemEditorialPicture
        | GlobalFourItemEditorialPicture
        | GlobalThreeItemEditorialSubtitleBgPicture
        | GlobalEditorialWithFeaturePicture
      ) & { widgetIndex: number })
  | (Omit<GlobalSetAndromeda, 'query' | 'banners'> & {
      banners: Omit<GlobalWidgetSiriusPicture, 'query' | 'id'>[]
      horizontalAlignment?: HorizontalAlignment
      hasBorder?: boolean
      widgetIndex: number
    })

const GlobalSetAndromedaProps = (props, theme) => {
  const editorialProps: EditorialProps = {
    columns: props.columns || props.banners.length,
    horizontalAlignment: props.horizontalAlignment || 'center',
    gutterPadding: theme.widget.editorialItem.andromedaEditorial.gutterPadding,
    hasBorder: true,
    wraps: [false],
    items:
      (props &&
        props.banners &&
        props.banners.map((item: any) => ({
          picture: item.picture,
          highlightProps: item.highlight
            ? { subtitle: item.highlight }
            : undefined,
          titleProps: item.title ? { title: item.title } : undefined,
          content: item.subTitle || undefined,
          buttonContent: item.button || undefined,
          buttonLink: item.link as string,
          emphasis: theme.widget.editorialItem.andromedaEditorial.ctaEmphasis,
          flexBasis: ['100%'],
          contentGutterPadding:
            theme.widget.editorialItem.andromedaEditorial.contentGutterPadding,
        }))) ||
      [],
    widgetIndex: props.widgetIndex,
  }
  return editorialProps
}

const GlobalTwoItemEditorialProps = (props, theme) => {
  const editorialProps: EditorialProps = {
    title: props.widgetTitle || undefined,
    titleStyle:
      theme.widget.editorialItem.twoItemEditorial.titleStyle || 'large1',
    subtitle: props.widgetSubtitle || undefined,
    horizontalAlignment:
      theme.widget.editorialItem.twoItemEditorial.horizontalAlignment,
    gutterPadding: theme.widget.editorialItem.twoItemEditorial.gutterPadding,
    wraps: [false],
    items: [
      {
        picture: props.pictures.pictureOne,
        titleProps: props.itemOneTitle
          ? { title: props.itemOneTitle }
          : undefined,
        content: props.itemOneDescription || undefined,
        buttonContent: props.itemOneCTAText || undefined,
        buttonLink: props.itemOneURL as string,
        emphasis: theme.widget.editorialItem.twoItemEditorial.ctaEmphasis,
        flexBasis: ['100%', 'calc(50% - 16px)', '50%'],
        contentGutterPadding:
          theme.widget.editorialItem.twoItemEditorial.contentGutterPadding,
        font: theme.widget.editorialItem.twoItemEditorial.itemTitleStyle,
        enableEditorialItemFocusOutline:
          theme.widget.editorialItem.twoItemEditorial
            .enableEditorialItemFocusOutline,
      },
      {
        picture: props.pictures.pictureTwo,
        titleProps: props.itemTwoTitle
          ? { title: props.itemTwoTitle }
          : undefined,
        content: props.itemTwoDescription || undefined,
        buttonContent: props.itemTwoCTAText || undefined,
        buttonLink: props.itemTwoURL as string,
        emphasis: theme.widget.editorialItem.twoItemEditorial.ctaEmphasis,
        flexBasis: ['100%', 'calc(50% - 16px)', '50%'],
        contentGutterPadding:
          theme.widget.editorialItem.twoItemEditorial.contentGutterPadding,
        font: theme.widget.editorialItem.twoItemEditorial.itemTitleStyle,
        enableEditorialItemFocusOutline:
          theme.widget.editorialItem.twoItemEditorial
            .enableEditorialItemFocusOutline,
      },
    ],
    widgetIndex: props.widgetIndex,
  }
  return editorialProps
}

const GlobalThreeItemEditorialProps = (props, theme) => {
  const editorialProps: EditorialProps = {
    title: props.widgetTitle || undefined,
    titleStyle: theme.widget.editorialItem.threeItemEditorial.titleStyle,
    subtitle: props.widgetSubtitle || undefined,
    horizontalAlignment:
      theme.widget.editorialItem.threeItemEditorial.horizontalAlignment,
    gutterPadding: theme.widget.editorialItem.threeItemEditorial.gutterPadding,
    wraps: [false, true, false],
    widgetIndex: props.widgetIndex,
    mobileCTAWidth:
      theme.widget.editorialItem.threeItemEditorial.mobileCTAWidth,
    items: [
      {
        picture: props.pictures.pictureOne,
        titleProps: props.itemOneTitle
          ? { title: props.itemOneTitle }
          : undefined,
        highlightProps: props.itemOneSubtitle
          ? { subtitle: props.itemOneSubtitle }
          : undefined,
        content: props.itemOneDescription || undefined,
        buttonContent: props.itemOneCTAText || undefined,
        buttonLink: props.itemOneUrl as string,
        emphasis: theme.widget.editorialItem.threeItemEditorial.ctaEmphasis,
        flexBasis: ['100%', 'calc(100% - 16px)', '33%'],
        directions: ['column', 'row', 'column'],
        contentGutterPadding:
          theme.widget.editorialItem.threeItemEditorial.contentGutterPadding,
        font: theme.widget.editorialItem.threeItemEditorial.itemTitleStyle,
        enableEditorialItemFocusOutline:
          theme.widget.editorialItem.threeItemEditorial
            .enableEditorialItemFocusOutline,
      },
      {
        picture: props.pictures.pictureTwo,
        titleProps: props.itemTwoTitle
          ? { title: props.itemTwoTitle }
          : undefined,
        highlightProps: props.itemTwoSubtitle
          ? { subtitle: props.itemTwoSubtitle }
          : undefined,
        content: props.itemTwoDescription || undefined,
        buttonContent: props.itemTwoCTAText || undefined,
        buttonLink: props.itemTwoUrl as string,
        emphasis: theme.widget.editorialItem.threeItemEditorial.ctaEmphasis,
        flexBasis: ['100%', 'calc(50% - 16px)', '33%'],
        contentGutterPadding:
          theme.widget.editorialItem.threeItemEditorial.contentGutterPadding,
        font: theme.widget.editorialItem.threeItemEditorial.itemTitleStyle,
        enableEditorialItemFocusOutline:
          theme.widget.editorialItem.threeItemEditorial
            .enableEditorialItemFocusOutline,
      },
      ...(props.itemThreeImage
        ? [
            {
              picture: props.pictures.pictureThree,
              titleProps: props.itemThreeTitle
                ? { title: props.itemThreeTitle }
                : undefined,
              highlightProps: props.itemThreeSubtitle
                ? { subtitle: props.itemThreeSubtitle }
                : undefined,
              content: props.itemThreeDescription || undefined,
              buttonContent: props.itemThreeCTAText || undefined,
              buttonLink: props.itemThreeUrl as string,
              emphasis:
                theme.widget.editorialItem.threeItemEditorial.ctaEmphasis,
              flexBasis: ['100%', 'calc(50% - 16px)', '33%'] as BreakpointArray<
                string
              >,
              contentGutterPadding:
                theme.widget.editorialItem.threeItemEditorial
                  .contentGutterPadding,
              font:
                theme.widget.editorialItem.threeItemEditorial.itemTitleStyle,
              enableEditorialItemFocusOutline:
                theme.widget.editorialItem.threeItemEditorial
                  .enableEditorialItemFocusOutline,
            },
          ]
        : []),
    ],
  }
  return editorialProps
}

const GlobalFourItemEditorialProps = (props, theme) => {
  const editorialProps: EditorialProps = {
    widgetIndex: props.widgetIndex,
    horizontalAlignment:
      theme.widget.editorialItem.fourItemEditorial.horizontalAlignment,
    title: props.widgetTitle || undefined,
    subtitle: props.widgetSubtitle || undefined,
    styleModifier: props.styleModifier || undefined,
    gutterPadding: theme.widget.editorialItem.fourItemEditorial.gutterPadding,
    wraps: [false],
    widths: ['100%', '200%', '100%'],
    items: [
      {
        picture: props.pictures.pictureOne,
        titleProps: props.itemOneTitle
          ? { title: props.itemOneTitle }
          : undefined,
        highlightProps: props.itemOneSubtitle
          ? { subtitle: props.itemOneSubtitle }
          : undefined,
        content: props.itemOneDescription || undefined,
        buttonContent: props.itemOneCTA || undefined,
        buttonLink: props.itemOneUrl as string,
        emphasis: theme.widget.editorialItem.fourItemEditorial.ctaEmphasis,
        flexBasis: ['100%', 'calc(50% - 16px)', '25%'],
        contentGutterPadding:
          theme.widget.editorialItem.fourItemEditorial.contentGutterPadding,
        enableEditorialItemFocusOutline:
          theme.widget.editorialItem.fourItemEditorial
            .enableEditorialItemFocusOutline,
      },
      ...(props.itemTwoImage
        ? [
            {
              picture: props.pictures.pictureTwo,
              titleProps: props.itemTwoTitle
                ? { title: props.itemTwoTitle }
                : undefined,
              highlightProps: props.itemTwoSubtitle
                ? { subtitle: props.itemTwoSubtitle }
                : undefined,
              content: props.itemTwoDescription || undefined,
              buttonContent: props.itemTwoCTA || undefined,
              buttonLink: props.itemTwoUrl as string,
              emphasis:
                theme.widget.editorialItem.fourItemEditorial.ctaEmphasis,
              flexBasis: ['100%', 'calc(50% - 16px)', '25%'] as BreakpointArray<
                string
              >,
              contentGutterPadding:
                theme.widget.editorialItem.fourItemEditorial
                  .contentGutterPadding,
              enableEditorialItemFocusOutline:
                theme.widget.editorialItem.fourItemEditorial
                  .enableEditorialItemFocusOutline,
            },
          ]
        : []),

      ...(props.itemThreeImage
        ? [
            {
              picture: props.pictures.pictureThree,
              titleProps: props.itemThreeTitle
                ? { title: props.itemThreeTitle }
                : undefined,
              highlightProps: props.itemThreeSubtitle
                ? { subtitle: props.itemThreeSubtitle }
                : undefined,
              content: props.itemThreeDescription || undefined,
              buttonContent: props.itemThreeCTA || undefined,
              buttonLink: props.itemThreeUrl as string,
              emphasis:
                theme.widget.editorialItem.fourItemEditorial.ctaEmphasis,
              flexBasis: ['100%', 'calc(50% - 16px)', '25%'] as BreakpointArray<
                string
              >,
              contentGutterPadding:
                theme.widget.editorialItem.fourItemEditorial
                  .contentGutterPadding,
              enableEditorialItemFocusOutline:
                theme.widget.editorialItem.fourItemEditorial
                  .enableEditorialItemFocusOutline,
            },
          ]
        : []),
      ...(props.itemFourImage
        ? [
            {
              picture: props.pictures.pictureFour,
              titleProps: props.itemFourTitle
                ? { title: props.itemFourTitle }
                : undefined,
              highlightProps: props.itemFourSubtitle
                ? { subtitle: props.itemFourSubtitle }
                : undefined,
              content: props.itemFourDescription || undefined,
              buttonContent: props.itemFourCTA || undefined,
              buttonLink: props.itemFourUrl as string,
              emphasis:
                theme.widget.editorialItem.fourItemEditorial.ctaEmphasis,
              flexBasis: ['100%', 'calc(50% - 16px)', '25%'] as BreakpointArray<
                string
              >,
              contentGutterPadding:
                theme.widget.editorialItem.fourItemEditorial
                  .contentGutterPadding,
              enableEditorialItemFocusOutline:
                theme.widget.editorialItem.fourItemEditorial
                  .enableEditorialItemFocusOutline,
            },
          ]
        : []),
    ],
    itemsCTAAlignment:
      (props.itemsCTAAlignment as HorizontalAlignment) || undefined,
  }
  return editorialProps
}

const GlobalThreeItemEditorialSubtitleBGProps = (props, theme) => {
  const editorialProps: EditorialProps = {
    title: props.title || undefined,
    widgetIndex: props.widgetIndex,
    subtitle: props.description || undefined,
    wraps: [false, true, false],
    gutterPadding:
      theme.widget.editorialItem.threeItemEditorialSubtitleBG.gutterPadding,
    items: [
      {
        picture: props.pictures.pictureOne,
        titleProps: props.itemOneTitle
          ? { title: props.itemOneTitle }
          : undefined,
        highlightProps: props.itemOneSubtitle
          ? { subtitle: props.itemOneSubtitle }
          : undefined,
        content: props.itemOneDescription || undefined,
        buttonContent: props.itemOneCTAText || undefined,
        buttonLink: props.itemOneUrl as string,
        emphasis:
          theme.widget.editorialItem.threeItemEditorialSubtitleBG.ctaEmphasis,
        flexBasis: ['100%', 'calc(100% - 16px)', '33%'],
        directions: ['column', 'row', 'column'],
        contentGutterPadding:
          theme.widget.editorialItem.threeItemEditorialSubtitleBG
            .contentGutterPadding,
      },
      {
        picture: props.pictures.pictureTwo,
        titleProps: props.itemTwoTitle
          ? { title: props.itemTwoTitle }
          : undefined,
        highlightProps: props.itemTwoSubtitle
          ? { subtitle: props.itemTwoSubtitle }
          : undefined,
        content: props.itemTwoDescription || undefined,
        buttonContent: props.itemTwoCTAText || undefined,
        buttonLink: props.itemTwoUrl as string,
        emphasis:
          theme.widget.editorialItem.threeItemEditorialSubtitleBG.ctaEmphasis,
        flexBasis: ['100%', 'calc(50% - 16px)', '33%'],
        contentGutterPadding:
          theme.widget.editorialItem.threeItemEditorialSubtitleBG
            .contentGutterPadding,
      },
      ...(props.itemThreeImage
        ? [
            {
              picture: props.pictures.pictureThree,
              titleProps: props.itemThreeTitle
                ? { title: props.itemThreeTitle }
                : undefined,
              highlightProps: props.itemThreeSubtitle
                ? { subtitle: props.itemThreeSubtitle }
                : undefined,
              content: props.itemThreeDescription || undefined,
              buttonContent: props.itemThreeCTAText || undefined,
              buttonLink: props.itemThreeUrl as string,
              emphasis:
                theme.widget.editorialItem.threeItemEditorialSubtitleBG
                  .ctaEmphasis,
              flexBasis: ['100%', 'calc(50% - 16px)', '33%'] as BreakpointArray<
                string
              >,
              contentGutterPadding:
                theme.widget.editorialItem.threeItemEditorialSubtitleBG
                  .contentGutterPadding,
            },
          ]
        : []),
    ],
  }
  return editorialProps
}

const GlobalEditorialWithFeatureProps = (props, theme) => {
  const editorialProps: EditorialProps = {
    title: props.title || undefined,
    widgetIndex: props.widgetIndex,
    subtitle: props.description || undefined,
    horizontalAlignment:
      theme.widget.editorialItem.editorialWithFeature.horizontalAlignment,
    gutterPadding:
      theme.widget.editorialItem.editorialWithFeature.gutterPadding,
    wraps: [false, true, false],
    items: [
      {
        picture: props.pictures.pictureOne,
        titleProps: props.titleItemOne
          ? { title: props.titleItemOne }
          : undefined,
        content: props.descriptionItemOne || undefined,
        buttonContent: props.CTAItemOne || undefined,
        buttonLink: props.linkItemOne || undefined,
        emphasis: theme.widget.editorialItem.editorialWithFeature.ctaEmphasis,
        flexBasis: ['100%', 'calc(50% - 16px)', '25%'],
        order: [2, 2, 1],
        contentGutterPadding:
          theme.widget.editorialItem.editorialWithFeature.contentGutterPadding,
        enableEditorialItemFocusOutline:
          theme.widget.editorialItem.editorialWithFeature
            .enableEditorialItemFocusOutline,
      },
      {
        picture: props.pictures.featuredPicture,
        titleProps: props.titleFeatured
          ? { title: props.titleFeatured }
          : undefined,
        content: props.descriptionFeatured || undefined,
        buttonContent: props.CTAFeatured || undefined,
        buttonLink: props.linkFeatured || undefined,
        emphasis: theme.widget.editorialItem.editorialWithFeature.ctaEmphasis,
        flexBasis: ['100%', 'calc(100% - 16px)', '50%'],
        order: [1, 1, 2],
        directions: ['column', 'row', 'column'],
        contentGutterPadding:
          theme.widget.editorialItem.editorialWithFeature.contentGutterPadding,
        enableEditorialItemFocusOutline:
          theme.widget.editorialItem.editorialWithFeature
            .enableEditorialItemFocusOutline,
      },
      {
        picture: props.pictures.pictureTwo,
        titleProps: props.titleItemTwo
          ? { title: props.titleItemTwo }
          : undefined,
        content: props.descriptionItemTwo || undefined,
        buttonContent: props.CTAItemTwo || undefined,
        buttonLink: props.linkItemTwo || undefined,
        emphasis: theme.widget.editorialItem.editorialWithFeature.ctaEmphasis,
        flexBasis: ['100%', `calc(50% - 16px)`, '25%'],
        order: [3],
        contentGutterPadding:
          theme.widget.editorialItem.editorialWithFeature.contentGutterPadding,
        enableEditorialItemFocusOutline:
          theme.widget.editorialItem.editorialWithFeature
            .enableEditorialItemFocusOutline,
      },
    ],
  }
  return editorialProps
}

export const EditorialWidgetRenderer = (
  props: EditorialWidgetRendererProps,
) => {
  const theme = useTheme()
  switch (props.__typename) {
    case WidgetTypes.GlobalSetAndromeda:
      return <Editorial {...GlobalSetAndromedaProps(props, theme)} />
    case WidgetTypes.GlobalTwoItemEditorial:
      return <Editorial {...GlobalTwoItemEditorialProps(props, theme)} />
    case WidgetTypes.GlobalThreeItemEditorial:
      return <Editorial {...GlobalThreeItemEditorialProps(props, theme)} />
    case WidgetTypes.GlobalFourItemEditorial:
      return <Editorial {...GlobalFourItemEditorialProps(props, theme)} />
    case WidgetTypes.GlobalThreeItemEditorialSubtitleBG:
      return (
        <Editorial {...GlobalThreeItemEditorialSubtitleBGProps(props, theme)} />
      )
    case WidgetTypes.GlobalEditorialWithFeature:
      return <Editorial {...GlobalEditorialWithFeatureProps(props, theme)} />
    default:
      return null
  }
}
